import Swiper from 'swiper/bundle';
import 'swiper/css'


const player = new Plyr('.player');

// const player = new Plyr('.player', {
//     config: {
//         fullscreen: { enabled: true, fallback: true, iosNative: 'force', container: null },
//         vimeo: {
//             playsinline: false
//         },
//     },
// });

player.on('ready', function() {

    var divs = document.querySelectorAll('.plyr__video-embed__container');

    [].forEach.call(divs, function(div) {
        // do whatever
        let oldurl = div.querySelector('iframe').src;
        let newurl = oldurl.replace("playsinline=true", "playsinline=false");
        div.querySelector('iframe').src = newurl;
    });
})

let scrollpos = window.scrollY
const header = document.querySelector("header")
const header_height = header.offsetHeight * 2;

const add_class_on_scroll = () => header.classList.add("sticky")
const remove_class_on_scroll = () => header.classList.remove("sticky")

window.addEventListener('scroll', function() {
    scrollpos = window.scrollY;
    if (scrollpos >= header_height) { add_class_on_scroll() } else { remove_class_on_scroll() }
})

const buttons = document.querySelectorAll(".inner-menu li a")
for (const button of buttons) {
    button.addEventListener('click', function(e) {

        if (this.classList.contains('read-more')) {
            e.preventDefault();
            let hidden_bio = document.querySelector('.hidden-bio');

            if (this.getAttribute('data-action') == 'show') {
                this.setAttribute('data-action', 'hide');
                this.innerHTML = 'Reduzir';
                hidden_bio.classList.toggle('visible');
            } else if (this.getAttribute('data-action') == 'hide') {
                this.setAttribute('data-action', 'show');
                this.innerHTML = 'Ver mais';
                hidden_bio.classList.toggle('visible');
                scrollTo(document.querySelector('.section-content'));


            }
        }
        if (this.classList.contains('anchor-link')) {
            e.preventDefault();
            let element = this.getAttribute('data-anchor');
            scrollTo(document.getElementById(element));
        }
    })
}

let drawer_icon = document.querySelector('.drawer-icon');
drawer_icon.addEventListener('click', () => {
    drawer_icon.classList.toggle('open');
    document.querySelector('#primary-menu').classList.toggle('drawer');
});


function scrollTo(element) {
    let header_offset = getTranslateValues(document.querySelector('header'));
    let scroll_distance = Number(element.offsetTop) + Number(header_offset.y) + 10;
    window.scroll({
        behavior: 'smooth',
        left: 0,
        top: scroll_distance
    });
}






const swiper = new Swiper('.swiper', {
    slidesPerView: 1,
    speed: 600,
    loop: true,
    mousewheel: {
        forceToAxis: true,
    },
    breakpoints: {

        // when window width is >= 640px
        640: {
            slidesPerView: 3,
        }
    }
});
document.querySelector('.swiper-button-next').addEventListener('click', () => {
    swiper.slideNext();
});

document.querySelector('.swiper-button-prev').addEventListener('click', () => {

    swiper.slidePrev();
});




function getTranslateValues(element) {
    const style = window.getComputedStyle(element)
    const matrix = style['transform'] || style.webkitTransform || style.mozTransform

    // No transform property. Simply return 0 values.
    if (matrix === 'none' || typeof matrix === 'undefined') {
        return {
            x: 0,
            y: 0,
            z: 0
        }
    }

    // Can either be 2d or 3d transform
    const matrixType = matrix.includes('3d') ? '3d' : '2d'
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === '2d') {
        return {
            x: matrixValues[4],
            y: matrixValues[5],
            z: 0
        }
    }

    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
        return {
            x: matrixValues[12],
            y: matrixValues[13],
            z: matrixValues[14]
        }
    }
}